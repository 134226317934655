import { Alert, Button, INodeProps, NodeTag } from '@msdyn365-commerce-modules/utilities';
import { Customer } from '@msdyn365-commerce/retail-proxy';
import * as React from 'react';
import { ICheckoutCustomerAccountPaymentResources } from '../checkout-customer-account-payment.props.autogenerated';

export interface IGetAccountPaymentEditViewFormInput {
    resources: ICheckoutCustomerAccountPaymentResources;
    amount: number;
    maxAmount: number;
    customer: Customer | undefined;
    customerCreatedDate: string;
    availableCredit: string;
    showCreditLimit: boolean;
    errorMessage: string | undefined;
    creditSectionIsExpanded: boolean;
    orderTotal: string;
    excessCredit: string | undefined;
    appliedAmount: string | undefined;
    onAddPayment(): Promise<void>;
    onChangePaymentAmount(newAmount: number): void;
    onToggleCreditSection(): void;
    onRemovePayment(): Promise<void>;
}

export interface IAccountPaymentEditViewForm {
    formProps: INodeProps;
    inputLabel: React.ReactNode;
    inputAmount: React.ReactNode;
    addPaymentButton: React.ReactNode;
    customerName: React.ReactNode;
    customerAccountNumber: React.ReactNode;
    customerSince: React.ReactNode;
    accountCredit: React.ReactNode;
    alert: React.ReactNode;
    accountDetails: React.ReactNode;
    appliedLine: React.ReactNode;
    bottomBorder: React.ReactNode;
}

// tslint:disable-next-line:max-func-body-length
export const getAccountPaymentFormEditMode = (options: IGetAccountPaymentEditViewFormInput): IAccountPaymentEditViewForm => {
    const {
        resources,
        onAddPayment,
        amount,
        onChangePaymentAmount,
        maxAmount,
        customer,
        customerCreatedDate,
        availableCredit,
        showCreditLimit,
        errorMessage,
        onToggleCreditSection,
        creditSectionIsExpanded,
        orderTotal,
        excessCredit,
        onRemovePayment,
        appliedAmount
    } = options;

    const { addPaymentButtonLabel, inputAmountLabel, inputAmountAriaLabel, customerSinceLabel, accountCreditLabel, creditDetailsSectionLabel, orderTotalLabel, availableCreditLabel, creditExcessLabel, appliedCustomerAccountPaymentLabel,
    removeCustomerAccountPaymentLabel } = resources;

    const inputLabel = (
        <span id='ms-checkout-customer-account__label' className='ms-checkout-customer-account__input-label'>
            {inputAmountLabel}
        </span>
    );

    const onAddAccountPayment = async (e: React.SyntheticEvent): Promise<void> => {
        e.preventDefault();
        await onAddPayment();
    };

    const formProps = {
        className: 'ms-checkout-customer-account__form-edit',
        onSubmit: onAddAccountPayment,
        tag: 'form' as NodeTag
    };

    const addPaymentButton = (
        <Button
            className='ms-checkout-customer-account__btn-pay'
            onClick={onAddAccountPayment}
            disabled={(amount > maxAmount) || (amount < 0) }
        >
            {addPaymentButtonLabel}
        </Button>
    );

    const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value || '0');
        onChangePaymentAmount(value);
    };

    const inputAmount = (
        <input
            type='number'
            className='ms-checkout-customer-account__input-amount form-control'
            value= {amount}
            onInput= {onChangeAmount}
            aria-label= {inputAmountAriaLabel}
            aria-valuemin= {0}
            aria-valuemax={maxAmount}
            aria-valuenow={amount}
            min = {0}
            max ={maxAmount}
        />
    );

    const customerName = (
        <div
            className='ms-checkout-customer-account__account-name'
        >
            {customer?.Name}
        </div>
    );

    const customerAccountNumber = (
        <div
            className='ms-checkout-customer-account__account-number'
        >
            {'#'}{customer?.AccountNumber}
        </div>
    );

    const customerSince = (
        <div
            className='ms-checkout-customer-account__customer-since'
        >
            {customerSinceLabel}{customerCreatedDate}
        </div>
    );

    const accountCredit = (
            <>
                <span
                    className='ms-checkout-customer-account__account-credit-label'
                >
                    {accountCreditLabel}
                </span>
                {showCreditLimit && <span
                    className='ms-checkout-customer-account__account-credit'
                >
                    {availableCredit}
                </span>}
                <span
                    className= {creditSectionIsExpanded ? 'ms-checkout-customer-account__account-credit-toggle-up' : 'ms-checkout-customer-account__account-credit-toggle-down'}
                    onClick={onToggleCreditSection}
                    role='button'
                />
            </>
    );

    const accountDetails = (
        creditSectionIsExpanded ?
        (
        <div
            className='ms-checkout-customer-account__credit-details-section'
        >
            <>
                <div
                    className='ms-checkout-customer-account__account-credit-current-order-preview'
                >
                    <span
                        className='ms-checkout-customer-account__account-credit-current-order-icon'
                    />
                    <span
                        className='ms-checkout-customer-account__account-credit-current-order-preview-label'
                    >
                        {creditDetailsSectionLabel}
                    </span>
                </div>
            </>
            <div
                className='ms-checkout-customer-account__available-credit-section'
            >
                <span
                    className='ms-checkout-customer-account__available-credit-label'
                >
                    {availableCreditLabel}
                </span>
                <span
                    className='ms-checkout-customer-account__available-credit'
                >
                    {availableCredit}
                </span>
            </div>
            <div
                className='ms-checkout-customer-account__order-total-section'
            >
                <span
                    className='ms-checkout-customer-account__order-total-label'
                >
                    {orderTotalLabel}
                </span>
                <span
                    className='ms-checkout-customer-account__order-total'
                >
                    {orderTotal}
                </span>
            </div>
            <div
                className='ms-checkout-customer-account__excess-credit-section'
            >
                <span
                    className='ms-checkout-customer-account__excess-credit-label'
                >
                    {creditExcessLabel}
                </span>
                <span
                    className='ms-checkout-customer-account__excess-credit'
                >
                    {excessCredit}
                </span>
            </div>
        </div>

        ): undefined
    );

    const alert = (
        <Alert
            tag='span'
            id='ms-checkout-customer-account__error'
            className='ms-checkout-customer-account__input-error'
            role='alert'
            assertive={false}
            fade={false}
            includeAlertClass={false}
            isOpen={!!errorMessage}
        >
            {errorMessage}
        </Alert>
    );

    const appliedLine = (
        appliedAmount ?
        (
            <div
                className='ms-checkout-customer-account__applied-line'
            >
                <span
                    className='ms-checkout-customer-account__applied-label'
                >
                    {appliedCustomerAccountPaymentLabel}
                </span>
                <span
                    className='ms-checkout-customer-account__applied-amount'
                >
                    {appliedAmount}
                </span>
                <a
                    className='ms-checkout-customer-account__remove'
                    onClick={onRemovePayment}
                    role='button'
                >
                    {removeCustomerAccountPaymentLabel}
                </a>
            </div>
        ) : undefined
    );

    const bottomBorder = (
        <div className = 'ms-checkout-customer-account__bottom-border' />
    );

    return {
        formProps,
        inputLabel,
        inputAmount,
        addPaymentButton,
        customerName,
        customerAccountNumber,
        customerSince,
        accountCredit,
        alert,
        accountDetails,
        appliedLine,
        bottomBorder
    };
};