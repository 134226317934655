import classnames from 'classnames';
import * as React from 'react';

import { ArrayExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { INodeProps } from '@msdyn365-commerce-modules/utilities';
import { IImageProps, IImageSettings, Image, IModule } from '@msdyn365-commerce/core';
import {
    Address, CartLine, LineDeliverySpecification, PickupTimeslotAvailability, SimpleProduct
} from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

export interface ICheckoutPickupGroupKey {
    pickupStoreId: string;
    deliveryMode: string;
}

export interface ICheckoutPickupGroup extends ICheckoutPickupGroupKey {
    pickupModeName: string;
    storeName: string;
    pickupAddress?: Address;
    availabilityState: PickupTimeslotAvailabilityState;
    groupErrorMessage?: string;
    dateErrorMessage?: string;
    timeslotErrorMessage?: string;
    lineDeliverySpecs: LineDeliverySpecification[];
    cartLines: ICartLineWithProduct[];

    availableDates: Date[];
    availableTimeslots: PickupTimeslotAvailability[];

    selectedDate?: Date;
    selectedTimeslot?: PickupTimeslotAvailability;
}

export enum PickupTimeslotAvailabilityState {
    None,
    Available,
    NotDefined,
    NotAvailable // all slots occupied
}

export interface ICartLineWithProduct {
    cartLine: CartLine;
    product?: SimpleProduct;
}

export interface ICheckoutPickupGroupViewProps {
    moduleProps: IModule;

    pickupGroupContainer: INodeProps;
    dateTimeslotContainer: INodeProps;

    pickupStoreId: string;
    pickupModeName: string;
    deliveryMode: string;

    groupDivider: React.ReactNode;
    groupHeader: React.ReactNode;
    groupErrorMessage: React.ReactNode;
    cartLineImages: React.ReactNode;
    pickupDateDropdown: React.ReactNode;
    pickupTimeslotDropdown: React.ReactNode;

    selectedDateTimeslot: React.ReactNode;
    pickupAddress: React.ReactNode;
}

export interface ICheckoutPickupErrorProps {
    errorMessage?: string;
}

export const CheckoutPickupError: React.FC<ICheckoutPickupErrorProps> = ({
    errorMessage
}) => {
    if (!errorMessage) {
        return null;
    }

    return (
        <span className='msc-alert msc-alert-noborder msc-alert-danger'>
            <span className='msi-exclamation-triangle' aria-hidden='true' />
            <span>{errorMessage}</span>
        </span>
    );
};

export interface ICheckoutPickupGroupHeaderProps {
    moduleClassName: string;
    storeInfo: string;
    items: string;
}

export const CheckoutPickupGroupHeader: React.FC<ICheckoutPickupGroupHeaderProps> = ({ moduleClassName, storeInfo, items }) => {
    return (
        <div className={`${moduleClassName}__group-header`}>
            <div className={`${moduleClassName}__group-header-main`}>{storeInfo}</div>
            <div className={`${moduleClassName}__group-header-item`}>{items}</div>
        </div>
    );
};

export interface ICheckoutPickupTimeslotInfoProps {
    moduleClassName: string;
    title: string;
    content: string;
}

export const CheckoutPickupTimeslotInfo: React.FC<ICheckoutPickupTimeslotInfoProps> = ({ moduleClassName, title, content }) => {
    return (
        <div className={`${moduleClassName}__group-selected`}>
            <div className={`${moduleClassName}__group-selected-title`}>{title}</div>
            <div className={`${moduleClassName}__group-selected-content`}>{content}</div>
        </div>
    );
};

export interface ICheckoutPickupAddressProps {
    moduleClassName: string;
    title: string;
    address?: Address;
}

export const CheckoutPickupAddress: React.FC<ICheckoutPickupAddressProps> = ({ moduleClassName, title, address }) => {
    if (!address) {
        return null;
    }

    return (
        <div className={`${moduleClassName}__group-address`}>
            <div className={`${moduleClassName}__group-address-header`}>{title}</div>
            {address.Name && <span className={`${moduleClassName}__group-address-name`}>{address.Name}</span>}
            {
                (<>
                    {address.Street && <span className={`${moduleClassName}__group-address-street`}>{address.Street}</span>}
                    {address.City && <span className={`${moduleClassName}__group-address-city`}>{address.City}</span>}
                    {address.State && <span className={`${moduleClassName}__group-address-state`}>{`, ${address.State}`}</span>}
                    {address.County && <span className={`${moduleClassName}__group-address-county`}>{`, ${address.County}`}</span>}
                    {address.ZipCode && <span className={`${moduleClassName}__group-address-zipcode`}>{` ${address.ZipCode}`}</span>}
                    {address.ThreeLetterISORegionName && <span className={`${moduleClassName}__group-address-threeletterisoregionname`}>{` ${address.ThreeLetterISORegionName}`}</span>}
                </>)
            }
        </div>
    );
};

export interface ICheckoutPickupCartLineInfo {
    lineId: string;
    imageProps: IImageProps;
    quantity: number;
}

export interface ICheckoutPickupCartLinesProps {
    moduleClassName: string;
    cartLines: ICheckoutPickupCartLineInfo[];
}

export const CheckoutPickupCartLines: React.FC<ICheckoutPickupCartLinesProps> = ({ moduleClassName, cartLines }) => {
    if (!ArrayExtensions.hasElements(cartLines)) {
        return null;
    }
    return (
        <div className={`${moduleClassName}__group-lines`}>
            {cartLines.map(prop => {
                const showQty = prop.quantity > 1;
                let className = `${moduleClassName}__group-product-image`;
                if (showQty) {
                    className = classnames(className, ' product-image-wide');
                }
                return (
                    <div className={className} key={prop.lineId}>
                        <Image {...prop.imageProps} />
                        {showQty && <div className={`${moduleClassName}__group-line-quantity`}>{prop.quantity}</div>}
                    </div>
                );
            })}
        </div>
    );
};

export const DefaultImageSettings: IImageSettings = {
    viewports: {
        xs: { q: `w=108&h=108&q=80&m=6&f=jpg"`, w: 108, h: 108 },
        lg: { q: `w=108&h=108&q=80&m=6&f=jpg`, w: 108, h: 108 },
        xl: { q: `w=108&h=108&q=80&m=6&f=jpg`, w: 108, h: 108 }
    },
    lazyload: true,
    quality: 80
};