import { computed } from 'mobx';
import * as React from 'react';

import { LabeledDropdown } from '@msdyn365-commerce-modules/utilities';
import {
    ILabeledDropdownOnChangeNotification, ILabeledDropdownOption
} from '@msdyn365-commerce-modules/utilities/dist/types/labeled-dropdown/LabeledDropdown.props';

import { CheckoutPickupError } from './checkout-pickup-group';
import { getDate } from './checkout-pickup-utility';

export interface IPickupDropdownProps {
    pickupStoreId: string;
    deliveryMode: string;

    className: string;
    labelClassname?: string;
    labelText: string;
    defaultText: string;
    errorMessage?: string;

    locale: string;

    // props for the LabeledDropdown component
    dropdownId: string;
    controlledByParent: boolean;
    toggleColor?: string;
    dropdownClassname?: string;
}

export interface IPickupDateDropdownProps extends IPickupDropdownProps {
    availableDates: Date[];
    selectedDate?: Date;
    setDateHandler(pickupStoreId: string, deliveryMode: string, pickupDate: Date | undefined): void;
}

/**
 *
 * CheckoutPickupDateDropdown component
 * @extends {React.Component<IPickupDateDropdownProps>}
 */
export class CheckoutPickupDateDropdown extends React.Component<IPickupDateDropdownProps> {

    private defaultKey: number = -1;
    private defaultOption: ILabeledDropdownOption;

    // as the LabeledDropdown control only offers key and value, we need to map the key with date and timeslot data
    @computed get getDateMap(): Map<string, Date> {
        const map = new Map<string, Date>();
        this.props.availableDates.forEach((value, index) => {
            map.set(index.toString(), value);
        });
        return map;
    }

    constructor(props: IPickupDateDropdownProps) {
        super(props);
        this.defaultOption = { key: this.defaultKey.toString(), value: props.defaultText };
        this.updateDateSelection = this.updateDateSelection.bind(this);
    }

    public render(): JSX.Element | null {
        const {
            className,
            labelClassname,
            labelText,
            errorMessage,
            dropdownId,
            availableDates,
            selectedDate,
            controlledByParent,
            toggleColor,
            dropdownClassname
        } = this.props;

        const { dropdownOptions, selectedOption } = this.mapDatesToOptions(availableDates, selectedDate);

        return (
            <div className={className}>
                <label className={labelClassname} htmlFor={dropdownId}>{labelText}</label>
                <CheckoutPickupError errorMessage={errorMessage} />
                <LabeledDropdown
                    dropdownId={dropdownId}
                    dropdownOptions={dropdownOptions}
                    selectedOption={selectedOption}
                    controlledByParent={controlledByParent}
                    toggleColor={toggleColor}
                    dropdownClassname={dropdownClassname}
                    onSelectOption={this.updateDateSelection}
                />
            </div>
        );
    }

    private updateDateSelection = (notification: ILabeledDropdownOnChangeNotification): void => {
        const {
            pickupStoreId,
            deliveryMode,
            setDateHandler,
        } = this.props;
        const date: Date | undefined = this.getDate(notification);
        setDateHandler(pickupStoreId, deliveryMode, date);
    }

    private getDate = (notification: ILabeledDropdownOnChangeNotification): Date | undefined => {
        const key: string = notification.selectedOption.key;
        if (key === this.defaultKey.toString() || !this.getDateMap.has(key)) {
            return undefined;
        } else {
            return this.getDateMap.get(key);
        }
    }

    private mapDatesToOptions = (dates: Date[], selectedDate: Date | undefined): { dropdownOptions: ILabeledDropdownOption[]; selectedOption: ILabeledDropdownOption | undefined } => {
        const { locale } = this.props;
        let options: ILabeledDropdownOption[] = [];
        let selectedOption: ILabeledDropdownOption = this.defaultOption;

        // tslint:disable-next-line:no-shadowed-variable
        options = dates.map((value: Date, index: number) => {
            return {
                key: index.toString(),
                value: getDate(value, locale)
            };
        });
        options.unshift(this.defaultOption);

        const index: number = dates.findIndex((value: Date) => (selectedDate !== undefined && selectedDate.getTime() === value.getTime()));
        if (index >= 0 && selectedDate) {
            selectedOption = {
                key: index.toString(),
                value: getDate(selectedDate, locale)
            };
        }

        return {
            dropdownOptions: options,
            selectedOption: selectedOption
        };
    }
}