import { computed } from 'mobx';
import * as React from 'react';

import { LabeledDropdown } from '@msdyn365-commerce-modules/utilities';
import {
    ILabeledDropdownOnChangeNotification, ILabeledDropdownOption
} from '@msdyn365-commerce-modules/utilities/dist/types/labeled-dropdown/LabeledDropdown.props';
import {
    PickupTimeslotAvailability
} from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

import { IPickupDropdownProps } from './checkout-pickup-date-dropdown';
import { CheckoutPickupError } from './checkout-pickup-group';
import { getTimeslot } from './checkout-pickup-utility';

export interface IPickupTimeslotDropdownProps extends IPickupDropdownProps {
    availableTimeslots: PickupTimeslotAvailability[];
    selectedTimeslot?: PickupTimeslotAvailability;
    timeslotPlaceHolder: string;
    setTimeslotHandler(pickupStoreId: string, deliveryMode: string, pickupTimeslot: PickupTimeslotAvailability | undefined): void;
}

/**
 *
 * CheckoutPickupTimeslotDropdown component
 * @extends {React.Component<IPickupTimeslotDropdownProps>}
 */
export class CheckoutPickupTimeslotDropdown extends React.Component<IPickupTimeslotDropdownProps> {

    private defaultKey: number = -1;
    private defaultOption: ILabeledDropdownOption;

    // as the LabeledDropdown control only offers key and value, we need to map the key with date and timeslot data
    @computed get getTimeslotMap(): Map<string, PickupTimeslotAvailability> {
        const map = new Map<string, PickupTimeslotAvailability>();
        this.props.availableTimeslots.forEach((value, index) => {
            map.set(index.toString(), value);
        });
        return map;
    }

    constructor(props: IPickupTimeslotDropdownProps) {
        super(props);
        this.defaultOption = { key: this.defaultKey.toString(), value: props.defaultText };
        this.updateTimeslotSelection = this.updateTimeslotSelection.bind(this);
    }

    public render(): JSX.Element | null {
        const {
            className,
            labelClassname,
            labelText,
            errorMessage,
            dropdownId,
            availableTimeslots,
            selectedTimeslot,
            controlledByParent,
            toggleColor,
            dropdownClassname
        } = this.props;

        const { dropdownOptions, selectedOption } = this.mapTimeslotsToOptions(availableTimeslots, selectedTimeslot);

        return (
            <div className={className}>
                <label className={labelClassname} htmlFor={dropdownId}>{labelText}</label>
                <CheckoutPickupError errorMessage={errorMessage} />
                <LabeledDropdown
                    dropdownId={dropdownId}
                    dropdownOptions={dropdownOptions}
                    selectedOption={selectedOption}
                    controlledByParent={controlledByParent}
                    toggleColor={toggleColor}
                    dropdownClassname={dropdownClassname}
                    onSelectOption={this.updateTimeslotSelection}
                />
            </div>
        );
    }

    private updateTimeslotSelection = (notification: ILabeledDropdownOnChangeNotification): void => {
        const {
            pickupStoreId,
            deliveryMode,
            setTimeslotHandler,
        } = this.props;
        const timeslot: PickupTimeslotAvailability | undefined = this.getTimeslot(notification);
        setTimeslotHandler(pickupStoreId, deliveryMode, timeslot);
    }

    private getTimeslot = (notification: ILabeledDropdownOnChangeNotification): PickupTimeslotAvailability | undefined => {
        const key: string = notification.selectedOption.key;
        if (key === this.defaultKey.toString() || !this.getTimeslotMap.has(key)) {
            return undefined;
        } else {
            return this.getTimeslotMap.get(key);
        }
    }

    private mapTimeslotsToOptions = (timeslots: PickupTimeslotAvailability[], selectedTimeslot: PickupTimeslotAvailability | undefined): { dropdownOptions: ILabeledDropdownOption[]; selectedOption: ILabeledDropdownOption | undefined } => {
        const { timeslotPlaceHolder, locale } = this.props;
        let options: ILabeledDropdownOption[] = [];
        let selectedOption: ILabeledDropdownOption = this.defaultOption;

        // tslint:disable-next-line:no-shadowed-variable
        options = timeslots.map((value: PickupTimeslotAvailability, index: number) => {
            return {
                key: index.toString(),
                value: getTimeslot(timeslotPlaceHolder, value.StartDateTime, value.EndDateTime, locale)
            };
        });
        options.unshift(this.defaultOption);

        const index: number = timeslots.findIndex(value => (
            selectedTimeslot !== undefined
            && selectedTimeslot.StartDateTime?.getTime() === value.StartDateTime?.getTime()
            && selectedTimeslot.EndDateTime?.getTime() === value.EndDateTime?.getTime()
        ));
        if (index >= 0 && selectedTimeslot) {
            selectedOption = {
                key: index.toString(),
                value: getTimeslot(timeslotPlaceHolder, selectedTimeslot.StartDateTime, selectedTimeslot.EndDateTime, locale)
            };
        }

        return {
            dropdownOptions: options,
            selectedOption: selectedOption
        };
    }

}