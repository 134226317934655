import { Button, INodeProps, NodeTag } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import { ICheckoutCustomerAccountPaymentResources } from '../checkout-customer-account-payment.props.autogenerated';

export interface IGetAccountPaymentSummaryViewFormInput {
    resources: ICheckoutCustomerAccountPaymentResources;
    amount: number;
    appliedAmount: string | undefined;
}

export interface IAccountPaymentSummaryViewForm {
    formProps: INodeProps;
    label: React.ReactNode;
    paymentAmount: React.ReactNode;
    addPaymentButton: React.ReactNode;
    appliedLine: React.ReactNode;
    bottomBorder: React.ReactNode;
}

export const getAccountPaymentFormSummaryMode = (options: IGetAccountPaymentSummaryViewFormInput): IAccountPaymentSummaryViewForm => {
    const {
        resources,
        amount,
        appliedAmount
    } = options;

    const { addPaymentButtonLabel, inputAmountSummaryLabel, appliedCustomerAccountPaymentLabel } = resources;

    const label = (
        <label id='ms-checkout-customer-account__label' className='ms-checkout-customer-account__input-label'>
            {inputAmountSummaryLabel}
        </label>
    );

    const formProps = {
        className: 'ms-checkout-customer-account__form-summary',
        tag: 'form' as NodeTag
    };

    const paymentAmount = (
        <label id='ms-checkout-customer-account__amount'>
            {amount}
        </label>
    );

    const addPaymentButton = (
        <Button
            className='ms-checkout-customer-account__btn-pay'
            disabled={true}
        >
            {addPaymentButtonLabel}
        </Button>
    );

    const appliedLine = (
        appliedAmount ?
        (
            <div
                className='ms-checkout-customer-account__applied-line'
            >
                <span
                    className='ms-checkout-customer-account__applied-label'
                >
                    {appliedCustomerAccountPaymentLabel}
                </span>
                <span
                    className='ms-checkout-customer-account__applied-amount'
                >
                    {appliedAmount}
                </span>
            </div>
        ) : undefined
    );

    const bottomBorder = (
        <div className = 'ms-checkout-customer-account__bottom-border' />
    );

    return {
        formProps,
        label,
        paymentAmount,
        addPaymentButton,
        appliedLine,
        bottomBorder
    };
};