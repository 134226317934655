import { format } from '@msdyn365-commerce-modules/utilities';

export function getDate(date: Date | undefined, locale: string): string {
    if (!date) {
        return '';
    }
    const options: Intl.DateTimeFormatOptions = {
        timeZone: 'UTC'
    };
    const formatter = new Intl.DateTimeFormat(locale, options);
    return formatter.format(new Date(date));
}

export const getTimeslot = (placeHolder: string, startDate: Date | undefined, endDate: Date | undefined, locale: string): string => {
    if (!startDate || !endDate) {
        return '';
    }
    const options: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'UTC'
    };
    const formatter = new Intl.DateTimeFormat(locale, options);
    return format(placeHolder, formatter.format(new Date(startDate)), formatter.format(new Date(endDate)));
};

export const getUTCDateWithoutTime = (input: Date): Date => {
    const date: Date = new Date(input);
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0));
};