import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import { ICheckoutPickupViewProps } from './checkout-pickup';
import { ICheckoutPickupGroupViewProps } from './components/checkout-pickup-group';

const CheckoutPickupView: React.FC<ICheckoutPickupViewProps> = props => {
    const {
        checkoutPickup,
        viewState,
        headerError,
        waiting,
        pickupGroups,
    } = props;

    return (
        <Module {...checkoutPickup}>
            {viewState.isLoading && waiting}
            {viewState.isHeaderError && headerError}
            {pickupGroups.map((pickupGroupViewProps: ICheckoutPickupGroupViewProps, index: number): React.ReactElement => {
                const {
                    pickupGroupContainer,
                    dateTimeslotContainer,
                    groupDivider,
                    groupHeader,
                    groupErrorMessage,
                    cartLineImages,
                    pickupDateDropdown,
                    pickupTimeslotDropdown,
                    selectedDateTimeslot,
                    pickupAddress
                } = pickupGroupViewProps;

                return (
                    <Node {...pickupGroupContainer} key={`checkout-pickup-group-${index}`} >
                        {groupDivider}
                        {groupHeader}
                        {groupErrorMessage}
                        {cartLineImages}
                        {viewState.isShowDropdown &&
                            <Node {...dateTimeslotContainer}>
                                {pickupDateDropdown}
                                {pickupTimeslotDropdown}
                            </Node>
                        }
                        {viewState.isShowSelected && pickupAddress}
                        {viewState.isShowSelected && selectedDateTimeslot}
                    </Node>
                );
            })}
        </Module>
    );

};

export default CheckoutPickupView;