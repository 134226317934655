import { buildCacheKey } from '@msdyn365-commerce-modules/retail-actions';
import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICommerceApiSettings, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { CheckoutState } from './checkout-state';

/**
 * CheckoutState - action input
 */
export class CheckoutStateInput implements IActionInput {
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings) {
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`CheckoutState`, this.apiSettings);
    public getCacheObjectType = () => 'CheckoutState';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * CheckoutState - action
 */
export async function checkoutStateAction(input: CheckoutStateInput, ctx: IActionContext): Promise<CheckoutState> {
    return new CheckoutState();
}

/**
 * CheckoutState - create new input for create action
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new CheckoutStateInput(inputData.requestContext.apiSettings);
};

/**
 * CheckoutState - create action
 */
export const checkoutStateActionDataAction =  createObservableDataAction<CheckoutState>({
    action: <IAction<CheckoutState>>checkoutStateAction,
    input: createInput
});

export default checkoutStateActionDataAction;